/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoRefugeeMessage } from '../models/DtoRefugeeMessage';
import type { DtoRefugeeMessageCreate } from '../models/DtoRefugeeMessageCreate';
import type { DtoRefugeeMessageDeleteBatch } from '../models/DtoRefugeeMessageDeleteBatch';
import type { DtoRefugeeMessageMarkComplete } from '../models/DtoRefugeeMessageMarkComplete';
import type { DtoRefugeeMessageUpdate } from '../models/DtoRefugeeMessageUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MessagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoRefugeeMessage Success
   * @throws ApiError
   */
  public getRefugeeMessages({
    id,
  }: {
    id: string,
  }): CancelablePromise<Array<DtoRefugeeMessage>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Messages/refugees/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns string Success
   * @throws ApiError
   */
  public create({
    requestBody,
  }: {
    requestBody?: DtoRefugeeMessageCreate,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Messages',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public update({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoRefugeeMessageUpdate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Messages/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public delete({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Messages/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public markComplete({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/Messages/{id}/mark-complete',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public markCompleteBatch({
    requestBody,
  }: {
    requestBody?: DtoRefugeeMessageMarkComplete,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/Messages/mark-complete-batch',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteBatch({
    requestBody,
  }: {
    requestBody?: DtoRefugeeMessageDeleteBatch,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Messages/batch',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
