/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoUser } from '../models/DtoUser';
import type { DtoUserCreate } from '../models/DtoUserCreate';
import type { DtoUserCreated } from '../models/DtoUserCreated';
import type { DtoUserDetails } from '../models/DtoUserDetails';
import type { DtoUserUpdate } from '../models/DtoUserUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoUser Success
   * @throws ApiError
   */
  public getUsers(): CancelablePromise<Array<DtoUser>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Users',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoUserCreated Success
   * @throws ApiError
   */
  public createUser({
    requestBody,
  }: {
    requestBody?: DtoUserCreate,
  }): CancelablePromise<DtoUserCreated> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Users',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoUserDetails Success
   * @throws ApiError
   */
  public getUserById({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoUserDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Users/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public updateUser({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoUserUpdate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Users/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public enableUser({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Users/{id}/enable',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public disableUser({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Users/{id}/disable',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
