/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoAudit } from '../models/DtoAudit';
import type { DtoDisabilityType } from '../models/DtoDisabilityType';
import type { DtoGenderType } from '../models/DtoGenderType';
import type { DtoNfcIdCreate } from '../models/DtoNfcIdCreate';
import type { DtoRefugee } from '../models/DtoRefugee';
import type { DtoRefugeeAddToFamily } from '../models/DtoRefugeeAddToFamily';
import type { DtoRefugeeBatchCreate } from '../models/DtoRefugeeBatchCreate';
import type { DtoRefugeeCreated } from '../models/DtoRefugeeCreated';
import type { DtoRefugeeDetails } from '../models/DtoRefugeeDetails';
import type { DtoRefugeeFamilyMember } from '../models/DtoRefugeeFamilyMember';
import type { DtoRefugeeStatusType } from '../models/DtoRefugeeStatusType';
import type { DtoRefugeeUpdate } from '../models/DtoRefugeeUpdate';
import type { DtoReligionType } from '../models/DtoReligionType';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RefugeesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoRefugee Success
   * @throws ApiError
   */
  public getRefugees({
    locId,
    refugeeId,
    roomId,
    roomNumber,
    familyId,
    email,
    passport,
    name,
    nfcId,
    loadMedicalData,
    status,
    gender,
    disability,
    religion,
    citizenshipId,
    limit,
    offset,
    sort,
  }: {
    /**
     * Location Id
     */
    locId?: string,
    /**
     * Refugee Id
     */
    refugeeId?: string,
    /**
     * Room Id
     */
    roomId?: string,
    /**
     * Room Number
     */
    roomNumber?: string,
    /**
     * Family Id
     */
    familyId?: string,
    /**
     * Email
     */
    email?: string,
    /**
     * Passport
     */
    passport?: string,
    /**
     * First name & Last name
     */
    name?: string,
    /**
     * NFC token
     */
    nfcId?: string,
    /**
     * Include some part of Medical Data (has a walker & wheelchair)
     */
    loadMedicalData?: boolean,
    status?: DtoRefugeeStatusType,
    /**
     * Gender type
     */
    gender?: DtoGenderType,
    /**
     * Disability type
     */
    disability?: DtoDisabilityType,
    /**
     * Religion type
     */
    religion?: DtoReligionType,
    /**
     * Citizens of certain countries
     */
    citizenshipId?: Array<string>,
    /**
     * Pagination: the numbers of items to return
     */
    limit?: number,
    /**
     * Pagination: the number of items to skip before starting to collect the result set
     */
    offset?: number,
    /**
     * Multi-column sort in format ?sort={optional_minus}{fieldName_1},{optional_minus}{fieldName_2},...,{optional_minus}{fieldName_N}. For descending sort use '-' before field name. Supported fields: createdat, updatedat, name, firstname, lastnameDefault field is '-updatedat'. Example: ?sort=firstname,-updatedat
     */
    sort?: string,
  }): CancelablePromise<Array<DtoRefugee>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Refugees',
      query: {
        'LocId': locId,
        'RefugeeId': refugeeId,
        'RoomId': roomId,
        'RoomNumber': roomNumber,
        'FamilyId': familyId,
        'Email': email,
        'Passport': passport,
        'Name': name,
        'NfcId': nfcId,
        'LoadMedicalData': loadMedicalData,
        'Status': status,
        'Gender': gender,
        'Disability': disability,
        'Religion': religion,
        'CitizenshipId': citizenshipId,
        'Limit': limit,
        'Offset': offset,
        'Sort': sort,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoRefugeeDetails Success
   * @throws ApiError
   */
  public getRefugeeById({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoRefugeeDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Refugees/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public updateRefugee({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoRefugeeUpdate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Refugees/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        409: `Conflict`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteRefugee({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Refugees/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoRefugeeCreated Success
   * @throws ApiError
   */
  public createRefugees({
    requestBody,
  }: {
    requestBody?: DtoRefugeeBatchCreate,
  }): CancelablePromise<DtoRefugeeCreated> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Refugees/batch',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public addToFamily({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoRefugeeAddToFamily,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Refugees/{id}/family',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteFromFamily({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Refugees/{id}/family',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoRefugeeFamilyMember Success
   * @throws ApiError
   */
  public getFamily({
    familyId,
    locationId,
  }: {
    familyId: string,
    locationId: string,
  }): CancelablePromise<Array<DtoRefugeeFamilyMember>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Refugees/families/{familyId}/locations/{locationId}',
      path: {
        'familyId': familyId,
        'locationId': locationId,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoAudit Success
   * @throws ApiError
   */
  public getRefugeeAudit({
    id,
  }: {
    id: string,
  }): CancelablePromise<Array<DtoAudit>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Refugees/{id}/audit',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public createNfcId({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoNfcIdCreate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Refugees/{id}/nfc',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteNfcId({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Refugees/{id}/nfc',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public checkIn({
    refugeeId,
    locationId,
  }: {
    refugeeId: string,
    locationId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Refugees/{refugeeId}/locations/{locationId}/checkin',
      path: {
        'refugeeId': refugeeId,
        'locationId': locationId,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public checkOut({
    refugeeId,
    locationId,
  }: {
    refugeeId: string,
    locationId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Refugees/{refugeeId}/locations/{locationId}/checkout',
      path: {
        'refugeeId': refugeeId,
        'locationId': locationId,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public restaurant({
    refugeeId,
    locationId,
  }: {
    refugeeId: string,
    locationId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Refugees/{refugeeId}/locations/{locationId}/restaurant',
      path: {
        'refugeeId': refugeeId,
        'locationId': locationId,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
}
