/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoRefugeeDetails } from '../models/DtoRefugeeDetails';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NfcService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoRefugeeDetails Success
   * @throws ApiError
   */
  public getRefugeeByNfcId({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoRefugeeDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Nfc/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
}
