/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoDepartment } from '../models/DtoDepartment';
import type { DtoDepartmentCreate } from '../models/DtoDepartmentCreate';
import type { DtoDepartmentUpdate } from '../models/DtoDepartmentUpdate';
import type { DtoUser } from '../models/DtoUser';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DepartmentsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoDepartment Success
   * @throws ApiError
   */
  public getDepartments(): CancelablePromise<Array<DtoDepartment>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Departments',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns string Created
   * @throws ApiError
   */
  public createDepartment({
    requestBody,
  }: {
    requestBody?: DtoDepartmentCreate,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Departments',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoDepartment Success
   * @throws ApiError
   */
  public getDepartmentById({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoDepartment> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Departments/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoDepartment Success
   * @throws ApiError
   */
  public updateDepartment({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoDepartmentUpdate,
  }): CancelablePromise<DtoDepartment> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Departments/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        409: `Conflict`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteDepartment({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Departments/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoUser Success
   * @throws ApiError
   */
  public getDepartmentsUsers({
    id,
  }: {
    id: string,
  }): CancelablePromise<Array<DtoUser>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Departments/{id}/users',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
}
