/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoInvite } from '../models/DtoInvite';
import type { DtoInviteConfirm } from '../models/DtoInviteConfirm';
import type { DtoInviteCreate } from '../models/DtoInviteCreate';
import type { DtoInviteDetails } from '../models/DtoInviteDetails';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InvitesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoInvite Success
   * @throws ApiError
   */
  public getInvites(): CancelablePromise<Array<DtoInvite>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Invites',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public createInvite({
    requestBody,
  }: {
    requestBody?: DtoInviteCreate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Invites',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoInviteDetails Success
   * @throws ApiError
   */
  public getInviteById({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoInviteDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Invites/{id}',
      path: {
        'id': id,
      },
      errors: {
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public confirmInvite({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoInviteConfirm,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Invites/{id}/confirm',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
