/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoScanner } from '../models/DtoScanner';
import type { DtoScannerCreate } from '../models/DtoScannerCreate';
import type { DtoScannerDetails } from '../models/DtoScannerDetails';
import type { DtoScannerUpdate } from '../models/DtoScannerUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ScannersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoScanner Success
   * @throws ApiError
   */
  public getScanners(): CancelablePromise<Array<DtoScanner>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Scanners',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns string Success
   * @throws ApiError
   */
  public addScanner({
    requestBody,
  }: {
    requestBody?: DtoScannerCreate,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Scanners',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoScannerDetails Success
   * @throws ApiError
   */
  public getScannerById({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoScannerDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Scanners/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public updateScanner({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoScannerUpdate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Scanners/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteScanner({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Scanners/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
