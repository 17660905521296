/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoRefugee } from '../models/DtoRefugee';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ImagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns any Success
   * @throws ApiError
   */
  public getImageById({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Images/refugees/{id}.webp',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoRefugee Success
   * @throws ApiError
   */
  public getImageByIdAndMaxSize({
    id,
    size,
  }: {
    id: string,
    size: number,
  }): CancelablePromise<Array<DtoRefugee>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Images/refugees/{id}/{size}.webp',
      path: {
        'id': id,
        'size': size,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
