/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoPasswordResetComplete } from '../models/DtoPasswordResetComplete';
import type { DtoPasswordResetRequest } from '../models/DtoPasswordResetRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PasswordResetService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns any Success
   * @throws ApiError
   */
  public getResetRequestById({
    requestId,
  }: {
    requestId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/password-reset/{requestId}',
      path: {
        'requestId': requestId,
      },
      errors: {
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public request({
    requestBody,
  }: {
    requestBody?: DtoPasswordResetRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/password-reset/request',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public confirm({
    requestBody,
  }: {
    requestBody?: DtoPasswordResetComplete,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/password-reset/confirm',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
