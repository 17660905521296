import { API } from '~/services/sdk/api'

export const useAPI = (withoutAuthorization?: boolean) => {
  const runtimeConfig = useRuntimeConfig()
  const identityService = useIdentityService()
  const { $i18n } = useNuxtApp()

  return new API({
    BASE: runtimeConfig.public.apiBase,
    async HEADERS(options) {
      if (withoutAuthorization)
        return {
          ...options.headers,
          'Accept-Language': $i18n.locale.value,
        }

      if (identityService.isExpired()) {
        const data = await identityService.requestNewTokens()
        await identityService.syncCookie(data)
      }

      const headers = options.headers
        ? Object.entries(options.headers).reduce<Record<string, string>>(
            (headers, [key, value]) => {
              headers[key] = value

              return headers
            },
            {},
          )
        : {}

      return {
        ...headers,
        Authorization: `Bearer ${identityService.data.accessToken}`,
        'Accept-Language': $i18n.locale.value,
      }
    },
  })
}
