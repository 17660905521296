/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LanguagesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns string Success
   * @throws ApiError
   */
  public getLanguages(): CancelablePromise<Record<string, string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Languages',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
}
