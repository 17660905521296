/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoTenant } from '../models/DtoTenant';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TenantsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoTenant Success
   * @throws ApiError
   */
  public getTenants(): CancelablePromise<Array<DtoTenant>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Tenants',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoTenant Success
   * @throws ApiError
   */
  public getLocationsByTenantId({
    id,
  }: {
    id: string,
  }): CancelablePromise<Array<DtoTenant>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Tenants/{id}/locations',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
}
