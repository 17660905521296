/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoDashboard } from '../models/DtoDashboard';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DashboardService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoDashboard Success
   * @throws ApiError
   */
  public getDashboard(): CancelablePromise<DtoDashboard> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoDashboard Success
   * @throws ApiError
   */
  public getDashboardByLocationId({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoDashboard> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Dashboard/locations/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
}
