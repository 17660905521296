/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoDisabilityType } from '../models/DtoDisabilityType';
import type { DtoGenderType } from '../models/DtoGenderType';
import type { DtoRefugee } from '../models/DtoRefugee';
import type { DtoReligionType } from '../models/DtoReligionType';
import type { DtoRoom } from '../models/DtoRoom';
import type { DtoRoomAddFamily } from '../models/DtoRoomAddFamily';
import type { DtoRoomAddRefugee } from '../models/DtoRoomAddRefugee';
import type { DtoRoomCreate } from '../models/DtoRoomCreate';
import type { DtoRoomType } from '../models/DtoRoomType';
import type { DtoRoomUpdate } from '../models/DtoRoomUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class RoomsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoRoom Success
   * @throws ApiError
   */
  public getRooms({
    roomId,
    roomNumber,
    minFreeSeats,
    gender,
    disability,
    religion,
    roomType,
    locId,
    incCitizenshipId,
    excCitizenshipId,
    limit,
    offset,
    sort,
  }: {
    /**
     * Room Id
     */
    roomId?: string,
    /**
     * Rooms whose number contains
     */
    roomNumber?: string,
    /**
     * Minimum number of free seats in room
     */
    minFreeSeats?: number,
    /**
     * Gender type
     */
    gender?: DtoGenderType,
    /**
     * Disability type
     */
    disability?: DtoDisabilityType,
    /**
     * Religion type
     */
    religion?: DtoReligionType,
    /**
     * Room type
     */
    roomType?: DtoRoomType,
    /**
     * Location Id
     */
    locId?: string,
    /**
     * Rooms with citizens of certain countries
     */
    incCitizenshipId?: Array<string>,
    /**
     * Numbers without citizens of certain countries
     */
    excCitizenshipId?: Array<string>,
    /**
     * Pagination: the numbers of items to return
     */
    limit?: number,
    /**
     * Pagination: the number of items to skip before starting to collect the result set
     */
    offset?: number,
    /**
     * Multi-column sort in format ?sort={optional_minus}{fieldName_1},{optional_minus}{fieldName_2},...,{optional_minus}{fieldName_N}. For descending sort use '-' before field name. Supported fields: createdat, updatedat, minFreeSeats, roomNumber. Default field is '-updatedat'. Example: ?sort=minFreeSeats,-roomNumber
     */
    sort?: string,
  }): CancelablePromise<Array<DtoRoom>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Rooms',
      query: {
        'RoomId': roomId,
        'RoomNumber': roomNumber,
        'MinFreeSeats': minFreeSeats,
        'Gender': gender,
        'Disability': disability,
        'Religion': religion,
        'RoomType': roomType,
        'LocId': locId,
        'IncCitizenshipId': incCitizenshipId,
        'ExcCitizenshipId': excCitizenshipId,
        'Limit': limit,
        'Offset': offset,
        'Sort': sort,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns string Success
   * @throws ApiError
   */
  public postRooms({
    requestBody,
  }: {
    requestBody?: DtoRoomCreate,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Rooms',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        409: `Conflict`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoRefugee Success
   * @throws ApiError
   */
  public getRefugeesByRoomId({
    id,
  }: {
    id: string,
  }): CancelablePromise<Array<DtoRefugee>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Rooms/{id}/refugees',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public addFamilyToRoom({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoRoomAddFamily,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Rooms/{id}/addfamily',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public addRefugeeToRoom({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoRoomAddRefugee,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Rooms/{id}/addrefugee',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public putRooms({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoRoomUpdate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Rooms/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`,
        409: `Conflict`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteRooms({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Rooms/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
