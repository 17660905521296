/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoTransfer } from '../models/DtoTransfer';
import type { DtoTransferCreate } from '../models/DtoTransferCreate';
import type { DtoTransferDirection } from '../models/DtoTransferDirection';
import type { DtoTransferRefugee } from '../models/DtoTransferRefugee';
import type { DtoTransferRefugeeCheckInReception } from '../models/DtoTransferRefugeeCheckInReception';
import type { DtoTransferRefugeeDetails } from '../models/DtoTransferRefugeeDetails';
import type { DtoTransfersRefugeesBatchCreate } from '../models/DtoTransfersRefugeesBatchCreate';
import type { DtoTransferStatus } from '../models/DtoTransferStatus';
import type { DtoTransferUpdate } from '../models/DtoTransferUpdate';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TransfersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoTransfer Success
   * @throws ApiError
   */
  public getTransfers({
    limit,
    offset,
    direction,
    status,
  }: {
    /**
     * Pagination: the numbers of items to return
     */
    limit?: number,
    /**
     * Pagination: the number of items to skip before starting to collect the result set
     */
    offset?: number,
    /**
     * All, In or Out transfers. Default value is 'All'.
     */
    direction?: DtoTransferDirection,
    /**
     * Transfer status. Default value is 'Active'. Incoming transfers cannot have the “Draft” status.
     */
    status?: DtoTransferStatus,
  }): CancelablePromise<Array<DtoTransfer>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Transfers',
      query: {
        'Limit': limit,
        'Offset': offset,
        'Direction': direction,
        'Status': status,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns string Success
   * @throws ApiError
   */
  public createTransfer({
    requestBody,
  }: {
    requestBody?: DtoTransferCreate,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Transfers',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoTransfer Success
   * @throws ApiError
   */
  public getTransferById({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoTransfer> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Transfers/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public updateTransfer({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoTransferUpdate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Transfers/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoTransfer Success
   * @throws ApiError
   */
  public getTransfersByLocationId({
    id,
    limit,
    offset,
    direction,
    status,
  }: {
    id: string,
    /**
     * Pagination: the numbers of items to return
     */
    limit?: number,
    /**
     * Pagination: the number of items to skip before starting to collect the result set
     */
    offset?: number,
    /**
     * All, In or Out transfers. Default value is 'All'.
     */
    direction?: DtoTransferDirection,
    /**
     * Transfer status. Default value is 'Active'. Incoming transfers cannot have the “Draft” status.
     */
    status?: DtoTransferStatus,
  }): CancelablePromise<Array<DtoTransfer>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Transfers/location/{id}',
      path: {
        'id': id,
      },
      query: {
        'Limit': limit,
        'Offset': offset,
        'Direction': direction,
        'Status': status,
      },
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoTransferRefugee Success
   * @throws ApiError
   */
  public getRefugeesFromTransfer({
    id,
  }: {
    id: string,
  }): CancelablePromise<Array<DtoTransferRefugee>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Transfers/{id}/refugees',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public addRefugeesToTransfer({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoTransfersRefugeesBatchCreate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Transfers/{id}/refugees',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Get information about a refugee from any company. The refugee must be added to the transfer.
   * @returns DtoTransferRefugeeDetails Success
   * @throws ApiError
   */
  public getRefugeeFromTransfer({
    id,
    refugeeId,
  }: {
    id: string,
    refugeeId: string,
  }): CancelablePromise<DtoTransferRefugeeDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Transfers/{id}/refugees/{refugeeId}',
      path: {
        'id': id,
        'refugeeId': refugeeId,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteRefugeeFromTransfer({
    id,
    refugeeId,
  }: {
    id: string,
    refugeeId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Transfers/{id}/refugees/{refugeeId}',
      path: {
        'id': id,
        'refugeeId': refugeeId,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Deactivate Refugee in the source company and modify status of Refugee inside Transfer
   * @returns any Success
   * @throws ApiError
   */
  public checkInTransfer({
    id,
    refugeeId,
  }: {
    id: string,
    refugeeId: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Transfers/{id}/refugees/{refugeeId}/check-in-transfer',
      path: {
        'id': id,
        'refugeeId': refugeeId,
      },
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * Transfer refugee from OLD company and location to the NEW company, location and room. Activating Refugee.
   * @returns any Success
   * @throws ApiError
   */
  public checkInReception({
    id,
    refugeeId,
    requestBody,
  }: {
    id: string,
    refugeeId: string,
    requestBody?: DtoTransferRefugeeCheckInReception,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Transfers/{id}/refugees/{refugeeId}/check-in-reception',
      path: {
        'id': id,
        'refugeeId': refugeeId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
