/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { FetchHttpRequest } from './core/FetchHttpRequest';
import { CountriesService } from './services/CountriesService';
import { DashboardService } from './services/DashboardService';
import { DepartmentsService } from './services/DepartmentsService';
import { GroupsService } from './services/GroupsService';
import { ImagesService } from './services/ImagesService';
import { InvitesService } from './services/InvitesService';
import { LanguagesService } from './services/LanguagesService';
import { LocationsService } from './services/LocationsService';
import { MedicalDataService } from './services/MedicalDataService';
import { MessagesService } from './services/MessagesService';
import { NfcService } from './services/NfcService';
import { PasswordResetService } from './services/PasswordResetService';
import { RefugeesService } from './services/RefugeesService';
import { ReportsService } from './services/ReportsService';
import { RoomsService } from './services/RoomsService';
import { ScannersService } from './services/ScannersService';
import { TenantsService } from './services/TenantsService';
import { TransfersService } from './services/TransfersService';
import { UsersService } from './services/UsersService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class API {
  public readonly countries: CountriesService;
  public readonly dashboard: DashboardService;
  public readonly departments: DepartmentsService;
  public readonly groups: GroupsService;
  public readonly images: ImagesService;
  public readonly invites: InvitesService;
  public readonly languages: LanguagesService;
  public readonly locations: LocationsService;
  public readonly medicalData: MedicalDataService;
  public readonly messages: MessagesService;
  public readonly nfc: NfcService;
  public readonly passwordReset: PasswordResetService;
  public readonly refugees: RefugeesService;
  public readonly reports: ReportsService;
  public readonly rooms: RoomsService;
  public readonly scanners: ScannersService;
  public readonly tenants: TenantsService;
  public readonly transfers: TransfersService;
  public readonly users: UsersService;
  public readonly request: BaseHttpRequest;
  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = FetchHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });
    this.countries = new CountriesService(this.request);
    this.dashboard = new DashboardService(this.request);
    this.departments = new DepartmentsService(this.request);
    this.groups = new GroupsService(this.request);
    this.images = new ImagesService(this.request);
    this.invites = new InvitesService(this.request);
    this.languages = new LanguagesService(this.request);
    this.locations = new LocationsService(this.request);
    this.medicalData = new MedicalDataService(this.request);
    this.messages = new MessagesService(this.request);
    this.nfc = new NfcService(this.request);
    this.passwordReset = new PasswordResetService(this.request);
    this.refugees = new RefugeesService(this.request);
    this.reports = new ReportsService(this.request);
    this.rooms = new RoomsService(this.request);
    this.scanners = new ScannersService(this.request);
    this.tenants = new TenantsService(this.request);
    this.transfers = new TransfersService(this.request);
    this.users = new UsersService(this.request);
  }
}

