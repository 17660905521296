/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoLocation } from '../models/DtoLocation';
import type { DtoLocationCreate } from '../models/DtoLocationCreate';
import type { DtoLocationDetails } from '../models/DtoLocationDetails';
import type { DtoLocationUpdate } from '../models/DtoLocationUpdate';
import type { DtoRoom } from '../models/DtoRoom';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LocationsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoLocation Success
   * @throws ApiError
   */
  public getLocations(): CancelablePromise<Array<DtoLocation>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Locations',
      errors: {
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns string Created
   * @throws ApiError
   */
  public createLocation({
    requestBody,
  }: {
    requestBody?: DtoLocationCreate,
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/Locations',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        409: `Conflict`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoLocationDetails Success
   * @throws ApiError
   */
  public getLocationById({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoLocationDetails> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Locations/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public updateLocation({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoLocationUpdate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/Locations/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteLocation({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/Locations/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        409: `Conflict`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoRoom Success
   * @throws ApiError
   */
  public getRoomsByLocationId({
    id,
  }: {
    id: string,
  }): CancelablePromise<Array<DtoRoom>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/Locations/{id}/rooms',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
