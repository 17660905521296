/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DtoMedicalData } from '../models/DtoMedicalData';
import type { DtoMedicalDataHistory } from '../models/DtoMedicalDataHistory';
import type { DtoMedicalDataUpdate } from '../models/DtoMedicalDataUpdate';
import type { DtoMedicalDataView } from '../models/DtoMedicalDataView';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MedicalDataService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns DtoMedicalData Success
   * @throws ApiError
   */
  public getMedicalData({
    id,
  }: {
    id: string,
  }): CancelablePromise<DtoMedicalData> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/medical-data/{id}',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public updateMedicalData({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: DtoMedicalDataUpdate,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/medical-data/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Bad Request`,
        403: `Forbidden`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoMedicalDataHistory Success
   * @throws ApiError
   */
  public getHistory({
    id,
  }: {
    id: string,
  }): CancelablePromise<Array<DtoMedicalDataHistory>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/medical-data/{id}/history',
      path: {
        'id': id,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
  /**
   * @returns DtoMedicalDataView Success
   * @throws ApiError
   */
  public getViews({
    id,
    offset,
    limit,
  }: {
    id: string,
    offset?: number,
    limit?: number,
  }): CancelablePromise<Array<DtoMedicalDataView>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/medical-data/{id}/views',
      path: {
        'id': id,
      },
      query: {
        'offset': offset,
        'limit': limit,
      },
      errors: {
        403: `Forbidden`,
        404: `Not Found`,
        500: `Server Error`,
      },
    });
  }
}
